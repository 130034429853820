#CommonTable {
  box-shadow: 0px 0px 7px gray;
  font-family: "MontserratRegular";
  .lable_name {
    font-weight: bold;
  }
  .table_light {
    font-family: "Montserrat";
    thead tr:nth-child(1) {
      th {
        background-color: #033762;
        color: white;
        font-family: "Montserrat";
        padding: 14px;
      }
    }
    tbody td {
      background-color: white;
      color: gray;
      font-family: "Montserrat";
      padding: 14px;
    }
    .filter_columns {
      border: none !important;
      border-bottom: 3px solid black !important;
    }
  }
  .table_dark {
    font-family: "Montserrat";
    thead tr:nth-child(1) {
      th {
        background-color: black;
        color: white;
        font-family: "Montserrat";
        padding: 14px;
      }
    }
    tbody td {
      background-color: black;
      color: white;
      font-family: "Montserrat";
      padding: 14px;
    }
    .filter_columns {
      border: none !important;
      border-bottom: 3px solid black !important;
    }
  }
  .table_wrapper {
    padding: 10px; // whole table is given a padding left to righ
  }
  .dashboard_loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
  .empty_dashboad_loader {
    height: 400px;
    position: relative;
  }
  .left_right_arrow {
    display: flex;
    justify-content: space-around;
    width: 18%;
    align-items: center;
    height: 50px;
    margin-top: 10px;
  }

  // detailed Table

  .detial_talbe {
    width: 100% !important;
    box-shadow: 0px 0px 7px gray;
    th {
      background-color: #999999;
      color: black;
      font-family: "Montserrat";
      padding: 14px;
    }
  }
  .dashboardCompanyName {
    color: #3c99f0;
    cursor: pointer;
  }
  .table_light {
    width: 100%;
    .column1 {
      width: 30%;
    }
    .column2 {
      width: 20%;
    }
    .column3 {
      width: 10%;
    }
    .column4 {
      width: 10%;
    }
    .column5 {
      width: 10%;
    }
    .column6 {
      width: 10%;
    }
  }
}
.preuw_header {
  display: flex;
  justify-content: center;
  font-family: "Monserate";
}
