#compPro {
  margin-bottom: 28px;
}

.pink_header {
  color: #497fbc;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  padding-bottom: 35px;
}

.font_family_montserrat_bold {
  font-family: "MontserratBold";
}

#compProfile {
  // background-image: url("../images/backimage1.png");
  #peo-select:invalid {
    color: rgb(117, 117, 117);
  }
  @media screen and (max-width: 992px) {
    .row {
      display: flex;
      flex-direction: column;
    }
    #phone-number,
    #entity-type,
    #years-in-business,
    #expected-start-date,
    #street-1,
    #zip-1,
    #city-1,
    #state-1,
    #street-2,
    #zip-2,
    #city-2,
    #state-2 {
      width: 100% !important;
    }
  }
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
  .class-desc-select {
    input {
      box-shadow: none !important;
    }
  }
  .pink-sign {
    font-weight: 800;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    background-color: #2c2e33;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .company-profile-container,
  .question-list-container,
  .location-payroll-container {
    margin-bottom: -15px;
  }
  .location-payroll-container {
    #perCKdollar {
      width: 6rem;
    }
    #adminPercent {
      width: 5rem;
    }
    #requested-pricing-radio-yes,
    #requested-pricing-radio-no {
      margin-inline: 10px;
      align-self: center;
    }
    input[type="radio"] {
      width: 18px;
      height: 18px;
      display: grid;
      place-content: center;
      background-color: transparent;
      cursor: pointer;
    }
    input[type="radio"]::after {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 40%;
      transform: scale(0);
      transition: 2s transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
    }
    input[type="radio"]:checked::after {
      transform: scale(1);
      border: 1px solid #0763ee83;
      box-shadow: #0763ee83;
      transition: 2s transform ease-in-out;
    }
  }
  .question-list-container {
    td {
      padding-left: 0px;
    }
  }

  .question-list-container {
    width: 80%;
    input[type="radio"] {
      width: 20px;
      height: 20px;
      display: grid;
      place-content: center;
      background-color: transparent;
      cursor: pointer;
    }
    input[type="radio"]:after {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 40%;
      transform: scale(0);
      transition: 2s transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
    }
    input[type="radio"]:checked:after {
      transform: scale(1);
      border: 1px solid #0763ee83;
      box-shadow: #0763ee83;
      transition: 2s transform ease-in-out;
    }
    .form-check-label {
      margin-left: 5px;
      font-size: 15px;
    }
  }
  .addLoc {
    margin-bottom: 18px;
  }
  .react-datepicker-wrapper {
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  #healthQues,
  #otp {
    font-size: inherit;
  }
  .formTitle {
    margin-bottom: 34px;
    font-size: 21px;
    font-weight: bold;
  }
  .company-locations,
  .emod-ques-heading {
    margin-bottom: 30px;
  }
  .mandate {
    color: #919399;
  }
  small {
    color: #333333;
  }
  .form-group {
    margin-bottom: 38px;
    select,
    input {
      height: 35px;
      color: black;
      // font-size: 14px;
      padding-left: 5px;
      // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
      box-shadow: 0.5px 1px 4px grey;
      border: white;
      width: 100%;
    }
    label {
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      // font-size: 11px;
      color: #333;
      margin-bottom: 0;
      &.mandatory::after {
        content: "*";
        padding-left: 2px;
      }
    }
    p.errMsg {
      color: red;
      font-size: 11px;
    }
  }
  textarea {
    // padding-left: 5px;
    height: 123px;
  }
  // #allLocations {
  //   margin-top: 6px;
  // }
  .emod-ques {
    margin-top: 44px;
  }
  .address-delete {
    margin-top: 1.2rem;
  }
  .linkLike {
    position: relative;
    bottom: 20px;
  }
  #otpSendMsg,
  #otpSentMsg,
  #otpVerify,
  #verifySuc {
    display: none;
    font-size: 0.8em;
  }
  #otpSec,
  #resendOTP {
    display: none;
  }
  #emailStatus,
  #verifyStatus {
    margin-top: 1rem;
  }
  #timerOTP {
    font-size: 0.8rem;
  }
  .btn-link {
    padding-left: 0;
  }
}

.btnSubmits {
  margin-top: 2rem;
  width: 210px;
  height: 40px;
  border-radius: 5px;
  background-color: #ffd13f;
  border-color: #eea236;
  box-shadow: none;
  color: white;
  font-weight: 800;
}

.btnSubmits {
  border-radius: 7.2px;
  background-color: #ffd13f;
  border-color: #eea236;
  box-shadow: 2px 4px 12px #eea236;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.btnSubmits {
  margin-top: 30px;
  margin-bottom: 36px;
}
.contactSaveButton{
  display: flex;
  gap:20px;
  align-items: baseline;
}